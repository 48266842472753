var openPhotoSwipe = function(items, index) {
    var pswpElement = document.querySelectorAll('.pswp')[0];

    // define options (if needed)
    var options = {
             // history & focus options are disabled on CodePen        
        history: false,
        index: index,

        showAnimationDuration: 0,
        hideAnimationDuration: 0
        
    };
    
    var gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);

    gallery.listen('gettingData', function(index, item) {
        if (item.w < 1 || item.h < 1) { // unknown size
        var img = new Image(); 
        img.onload = function() { // will get size after load
        item.w = this.width; // set image width
        item.h = this.height; // set image height
               gallery.invalidateCurrItems(); // reinit Items
               gallery.updateSize(true); // reinit Items
            }
        img.src = item.src; // let's download image
        }
    });

    gallery.init();


};

jQuery.fn.extend({
  ContentPhotoSwipe: function () {
    var galleryElement = $(this);
    galleryElement.append(spwp_root_element());

    var items = new Array();
    var images = galleryElement.find('img.lozad');

    images.each(function(n) {

      if (!$(this).parent('a').length) {
        $(this).css({ 'cursor' : 'zoom-in' });        
      }

      var is_large = $(this).attr('data-src').includes('_large');
      if (is_large) {
        var src = $(this).attr('data-src').replace("_large.", ".");
      } else {
        var src = $(this).attr('data-src').replace("_medium.", ".");
      }
      
      var item = {
        src: src,
        w: 0,
        h: 0
      }

      items.push(item);
    });

    galleryElement.find('img').on('click', function() {
      var index = images.index($(this));

      /* If image has parent a link */
      if (!$(this).parent('a').length) {
        openPhotoSwipe(items, index);
      } else {
        console.log('image has link');
      }
      
    });

    return;
  }
});

function spwp_root_element() {
  return '<div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">'+
    '<div class="pswp__bg"></div>'+
    '<div class="pswp__scroll-wrap">'+
        '<div class="pswp__container">'+
            '<div class="pswp__item"></div>'+
            '<div class="pswp__item"></div>'+
            '<div class="pswp__item"></div>'+
        '</div>'+
        '<div class="pswp__ui pswp__ui--hidden">'+
          '<div class="pswp__top-bar">'+
            '<div class="pswp__counter"></div>'+
              '<button class="pswp__button pswp__button--close" title="Close (Esc)"></button>'+
              '<button class="pswp__button pswp__button--share" title="Share"></button>'+
              '<button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button>'+
              '<button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button>'+
              '<div class="pswp__preloader">'+
                  '<div class="pswp__preloader__icn">'+
                    '<div class="pswp__preloader__cut">'+
                      '<div class="pswp__preloader__donut"></div>'+
                    '</div>'+
                  '</div>'+
              '</div>'+
            '</div>'+
            '<div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">'+
                '<div class="pswp__share-tooltip"></div>'+
            '</div>'+
            '<button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)">'+
            '</button>'+
            '<button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)">'+
            '</button>'+
            '<div class="pswp__caption">'+
                '<div class="pswp__caption__center"></div>'+
            '</div>'+
          '</div>'+
        '</div>'+
    '</div>';
}
